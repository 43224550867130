
.main-title {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 16px;
  margin: 16px 0;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left:0;
    width: 100%;
    max-width: 450px;
    height: 5px;
    background: $primary;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 75px;
    height: 5px;
    background: $success;
  }
}
