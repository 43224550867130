body {
  font-family: $bodyfont;
  background: $bodyColor;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: $bodyfont;
}
