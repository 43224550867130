@import "variables";
@import "./theme.scss";
@import "/node_modules/primeicons/primeicons.css";
@import "/node_modules/primeflex/primeflex.css";
@import "global/normalize";
@import "components/input";
@import "components/button";
@import "components/container";
@import "components/titles";


.p-dialog {
  margin: 16px !important;
}

.p-overlay {
  max-width: 100%
}
