.field-flex {
  display: flex;
  flex-direction: column;
  input, .p-dropdown, .p-calendar, .p-fileupload-choose, .p-inputnumber, .p-password {
    width: 100%;
    outline: none !important;
  }
}
input, p-dropdown {
  border:none !important;
  border-bottom: 1px solid $primary !important;
  border-radius: 0 !important;
}
.p-dropdown {
  border: 0;
}
.field > label {
  font-weight: 600 !important;
  font-size: 16px !important
}
